// nuxt-ui/stores/competitionStore.ts

/**
 * WARNING: SeasonCluster requests, returns all gender seasonsSplits. The response MUST be filtered by the competition gender associated, depending on webContext<br>
 * @kama seasonCluster - ej: 2023<br>
 * @Kama season - ej: 2023 Winter Split<br>
 * @Kama phase - ej: regular phase, final four, etc.<br>
 * @Kama group - ej: gruop A, group B<br>
 * @kama turn - ej: match-week 1, semifinal, final<br>
 * @Kama match<br>
 * */

import type { SeasonCluster } from "~/src/season-cluster/domain/models/00.SeasonCluster";
import type { Team } from "~/src/team/domain/models/Team";
import type { Season } from "~/src/season-cluster/domain/models/01.Season";
import type { Region } from "~/src/region/domain/models/Region";
import { useSeasonClusters } from "~/nuxt-ui/Shared/composables/season/seasonClusters";
import { useSeasons } from "~/nuxt-ui/Shared/composables/season/seasons";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import competitionUiService from "~/src/competition/infrastructure/ui-services/competitionUiService";
import regionUiService from "~/src/region/infrastructure/ui-services/regionUiService";

export const useCompetitionStore = defineStore("competitionStore", () => {
  const { getSeasonClusters } = useSeasonClusters();
  const { getSeason, getSeasonTeams } = useSeasons();
  const { findCurrentSeasonCluster, findCurrentSeasonId } = competitionUiService();
  const { getCurrentRegion } = regionUiService();
  const { webContextGenders, webContext } = useWebContext();

  const seasonClusters = ref<SeasonCluster[] | undefined>([]);
  const currentSeasonCluster = ref<SeasonCluster>();
  const currentSeasons = ref<Season[]>([]); // Current Season Cluster Seasons
  const currentSeason = ref<Season>(); // Current Season Cluster Season
  const currentSeasonTeams = ref<Team[]>([]);
  const currentRegion = ref<Region>();
  const stateTimeStamp = ref<number>();

  const loadState = async (region?: Region): Promise<void> => {
    currentRegion.value = region || (await getCurrentRegion());

    if (currentRegion.value) {
      try {
        seasonClusters.value = await getSeasonClusters(
          webContextGenders,
          currentRegion.value.competitionId[webContext]
        );

        if (seasonClusters.value) {
          currentSeasonCluster.value = findCurrentSeasonCluster(seasonClusters.value);
          currentSeasons.value = currentSeasonCluster.value ? currentSeasonCluster.value.seasons : [];

          const currentSeasonId = currentSeasons.value.length
            ? findCurrentSeasonId(currentSeasons.value)
            : undefined;

          currentSeason.value = !!currentSeasonId ? await getSeason(currentSeasonId) : undefined;

          if (currentSeason.value && !!currentSeasonId) {
            currentSeasonTeams.value = await getSeasonTeams(currentSeasonId, { withStaff: true });
          }
        }

        stateTimeStamp.value = Date.now();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const reloadCurrentRegion = async () => {
    try {
      currentRegion.value = await getCurrentRegion();
    } catch (e) {
      console.error("Error reloading current region:", e);
    }
  };

  const checkCompetitionStoreState = async () => {
    if (!stateTimeStamp) {
      await loadState();
    }
  };

  /* If needed to optimize getSeason requests
  const reloadCurrentSeason = async () => {
    if (stateTimeStamp.value) {
      const diffInSec = (Date.now() - stateTimeStamp.value) / 1000;
      if (diffInSec > 15) {
        if (!currentSeason.value) {
          await loadState();
        } else {
          currentSeason.value = await getSeason(currentSeason.value.id);
        }
      }
    }
  };*/

  return {
    seasonClusters,
    currentSeasons,
    currentSeasonCluster,
    currentSeason,
    currentSeasonTeams,
    region: currentRegion,
    loadState,
    checkCompetitionStoreState,
    reloadCurrentRegion,
  };
});

/*
 * Temporada 23/24
 * ES
 *   KINGS
 *     - Split 1 (2023)
 *     - Split2 (2023)
 *     - Kings cup (2023)
 *     - Kingdom Cup (2023)
 *     - Split 3  (gener 2024)
 *   QUEENS
 *     - Split 1 (2023)
 *     - Kings cup (2023)
 *     - Kingdom Cup (2023)
 *     - Split 2  (gener 2024)
 *
 * AMERICAS
 *   KIMGS
 *     - Split 1 (gener 2024)
 *
 * Temporada 24/25
 *  -...
 *
 * */

// src/season-cluster/domain/services/seasonService.ts

import type { Season, SeasonMeta } from "~/src/season-cluster/domain/models/01.Season";
import type { Phase } from "~/src/season-cluster/domain/models/02.Phase";
import type { SeasonDTO } from "~/src/season-cluster/infrastructure/DTO/01.SeasonDTO";

type SeasonService = {
  normalizeSeason: (season: SeasonDTO | Season) => Season;
  findSeasonStandingPhase: (season: Season) => Phase | undefined;
};

const service = (): SeasonService => ({
  normalizeSeason,
  findSeasonStandingPhase,
});
export default service;

function normalizeSeason(seasonDto: SeasonDTO | Season): Season {
  if (!seasonDto?.metaInformation) return seasonDto as Season;

  if (!seasonDto) {
    console.error("Impossible to normalize season. seasonDTO not provided.");
    return seasonDto as Season;
  }

  try {
    const _seasonMeta = {} as SeasonMeta;

    if (seasonDto?.metaInformation?.position_legend) {
      _seasonMeta.position_legend =
        typeof seasonDto.metaInformation.position_legend === "string"
          ? JSON.parse(seasonDto.metaInformation.position_legend)
          : JSON.parse(JSON.stringify(seasonDto.metaInformation.position_legend));
    }

    if (seasonDto?.metaInformation?.secondary_position_legend) {
      _seasonMeta.secondary_position_legend =
        typeof seasonDto.metaInformation.secondary_position_legend === "string"
          ? JSON.parse(seasonDto.metaInformation.secondary_position_legend)
          : JSON.parse(JSON.stringify(seasonDto.metaInformation.secondary_position_legend));
    }

    if (seasonDto?.metaInformation?.stats_partner) {
      _seasonMeta.stats_partner =
        typeof seasonDto.metaInformation.stats_partner === "string"
          ? JSON.parse(seasonDto.metaInformation.stats_partner)
          : JSON.parse(JSON.stringify(seasonDto.metaInformation.stats_partner));
    }

    if (seasonDto?.metaInformation?.season_partners) {
      _seasonMeta.season_partners =
        typeof seasonDto.metaInformation.season_partners === "string"
          ? JSON.parse(seasonDto.metaInformation.season_partners)
          : JSON.parse(JSON.stringify(seasonDto.metaInformation.season_partners));
    }

    let _season = JSON.parse(JSON.stringify(seasonDto)) as Season;
    _season.metaInformation = _seasonMeta;

    return _season;
  } catch (e) {
    console.error("Impossible to normalize season. ", e);
    return seasonDto as Season;
  }
}

function findSeasonStandingPhase(season: Season): Phase | undefined {
  if (!season.phases.length) return undefined;

  const phaseWithStandings = season.phases.filter(phase => {
    return phase.groups.find(group => group.hasStandings);
  });
  return phaseWithStandings[phaseWithStandings.length - 1];
}

// nuxt-ui/Shared/composables/season/groups.ts

import type { GroupDTO } from "~/src/season-cluster/infrastructure/DTO/03.GroupDTO";

interface UseGroups {
  getGroup: (groupId: number) => Promise<GroupDTO | undefined>;
}

export const useGroups = (): UseGroups => {
  return {
    getGroup,
  };

  async function getGroup(groupId: number): Promise<GroupDTO | undefined> {
    try {
      let path = `/api/v1/competition/groups/${groupId}`;
      const group = await $fetch<GroupDTO>(path);

      if (group) {
        return group;
      } else {
        console.error("Fetched group is null");
      }
    } catch (e) {
      console.error("Error fetching group:", e);
    }
  }
};

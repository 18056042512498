// nuxt-ui/Shared/composables/webContext.ts

import type { Gender, WebContext } from "~/src/Shared/Types";

interface UseWebContext {
  webContext: WebContext;
  isKings: boolean;
  isQueens: boolean;
  //isPrince: boolean;
  webContextGenders: Gender[];
  contextOgImage: string;
  contextSiteTitle: string;
  contextDomain: string;
}

export function useWebContext(): UseWebContext {
  const webContext: WebContext =
    (process.env?.THEME_CONTEXT as WebContext) || (useRuntimeConfig().public.webContext as WebContext);
  const isKings = webContext === "kings";
  const isQueens = webContext === "queens";
  //const isPrince = webContext === "prince";
  const contextOgImage = isKings ? "/img/generic-og-kl.jpg" : "/img/generic-og-ql.jpg";
  const contextSiteTitle = isQueens ? "Queens League" : "Kings League";
  const contextDomain = isQueens ? "queensleague.pro" : "kingsleague.pro";

  return {
    webContext,
    isKings,
    isQueens,
    //isPrince,
    webContextGenders: isQueens ? ["female", "both"] : ["male", "both"],
    contextOgImage,
    contextSiteTitle,
    contextDomain,
  };
}

<!--
// /error.vue
-->
<script setup>
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";

const error = useError();
const { contextSiteTitle } = useWebContext();

useSeoMeta({
  title: contextSiteTitle + " | " + error.value.statusCode,
});
</script>

<template>
  <nuxt-layout>
    <div class="bg-gray-900 py-16 md:py-24 flex justify-center items-center min-h-[calc(100vh-20vh)]">
      <div class="max-w-2xl text-center mx-auto space-y-8">
        <h1 class="text-7xl md:text-9xl text-primary font-extrabold">{{ error?.statusCode }}</h1>
        <div v-if="error?.statusCode === 404">
          <h3 class="error-message">
            {{ $t("error.404.message") }}
          </h3>
          <p class="text-lg text-gray-400 my-8">{{ $t("error.404.text.offside") }}</p>
          <a class="text-sm btn btn-emphasis" href="/">{{ $t("error.404.button") }}</a>
        </div>
        <p v-else class="error-message">{{ error?.message }}</p>
      </div>
    </div>
  </nuxt-layout>
</template>

<style scoped>
.error-message {
  @apply text-4xl font-extrabold;
}
</style>

import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import region_45guard_45global from "/usr/src/app/nuxt-ui/middleware/regionGuard.global.ts";
export const globalMiddleware = [
  validate,
  region_45guard_45global
]
export const namedMiddleware = {
  "not-found-americas": () => import("/usr/src/app/nuxt-ui/middleware/notFoundAmericas.ts"),
  "not-found-italy": () => import("/usr/src/app/nuxt-ui/middleware/notFoundItaly.ts"),
  "not-found-kings": () => import("/usr/src/app/nuxt-ui/middleware/notFoundKings.ts"),
  "not-found-queens": () => import("/usr/src/app/nuxt-ui/middleware/notFoundQueens.ts"),
  "not-found-queens-americas": () => import("/usr/src/app/nuxt-ui/middleware/notFoundQueensAmericas.ts"),
  "not-found-spain": () => import("/usr/src/app/nuxt-ui/middleware/notFoundSpain.ts"),
  "not-found-world-cup": () => import("/usr/src/app/nuxt-ui/middleware/notFoundWorldCup.ts")
}
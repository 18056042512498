// nuxt-ui/Shared/composables/teams.ts

import type { Team, TeamRankingParameter } from "~/src/team/domain/models/Team";
import type { Season, SeasonTeamMatches } from "~/src/season-cluster/domain/models/01.Season";
import type { Staff } from "~/src/team/domain/models/Staff";
import type { TeamDTO, TeamStaffDTO } from "~/src/team/infrastructure/DTO/TeamDTO";
import type { Match } from "~/src/match/domain/models/Match";
import type { Player } from "~/src/player/domain/Player";
import type { StandingDTO } from "~/src/standing/infrastructure/DTO/StandingDTO";
import type { TurnDTO } from "~/src/season-cluster/infrastructure/DTO/04.TurnDTO";
import type { RegionOption } from "~/src/region/domain/models/Region";
import { teamArrToObjById } from "~/src/team/domain/services/teamService";
import { useGroups } from "~/nuxt-ui/Shared/composables/season/groups";
import { useSeasons } from "~/nuxt-ui/Shared/composables/season/seasons";
import RegionUiService from "~/src/region/infrastructure/ui-services/regionUiService";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";

interface useTeams {
  teamArrToObjById: (teams: Team[], seasonId?: number) => Promise<Record<number, Team>>;
  getTeam: (teamId: number) => Promise<Team | undefined>;
  getTeamSeasons: (teamId: number) => Promise<Season[] | undefined>;
  useFetchParamsTeamSeasons: (teamId: number) => string;
  findTeamCurrentOrRecentSeason: (teamSeasons: Season[]) => Season | undefined;
  findTeamCompetitionsIds: (teamSeasons: Season[]) => Promise<RegionOption[]>;
  getCurrentTeamStaff: (teamId: number) => Promise<Staff[] | undefined>;
  getSeasonTeamStaff: (seasonId: number, teamId: number) => Promise<Staff[] | undefined>;
  findTeamPresident: (team: Team) => Staff | undefined;
  // getSeasonTeamStats: (seasonId: number, teamId: number) => Promise<Team | undefined>;
  getSeasonTeamMatches: (seasonId: number, teamId: number) => Promise<Match[] | undefined>;
  getTeamGroupId: (seasonId: number, teamId: number) => Promise<number | undefined>;
  getTeamStanding: (groupId: number, teamId: number) => Promise<StandingDTO | undefined>;
  getSeasonTeamRankings: (seasonId: number, teamId: number) => Promise<TeamRankingParameter[] | undefined>;
  getSeasonTeamPlayers: (seasonId: number, teamId: number) => Promise<Player[] | undefined>;
  getLastTurnPlayedByTeamInSeason: (seasonId: number, teamId: number) => Promise<string | undefined>;
}

export const useTeams = (): useTeams => {
  return {
    teamArrToObjById,
    getTeam,
    getTeamSeasons,
    useFetchParamsTeamSeasons,
    findTeamCurrentOrRecentSeason,
    findTeamCompetitionsIds,
    getCurrentTeamStaff,
    getSeasonTeamStaff,
    findTeamPresident: findTeamManager,
    // getSeasonTeamStats,
    getSeasonTeamMatches,
    getTeamGroupId,
    getTeamStanding,
    getSeasonTeamRankings,
    getSeasonTeamPlayers,
    getLastTurnPlayedByTeamInSeason,
  };

  async function getTeam(teamId: number): Promise<Team | undefined> {
    try {
      const path = `/api/v1/competition/teams/${teamId}`;
      const team = await $fetch<Team>(path);
      if (team) {
        return team;
      } else {
        console.error("Fetched team is null");
      }
    } catch (e) {
      if (e.statusCode === 404) {
        throw createError({ statusCode: 404, statusMessage: "Team not found" });
      } else {
        console.error("Error fetching team:", e);
      }
    }
  }

  async function getTeamSeasons(teamId: number): Promise<Season[] | undefined> {
    try {
      const path = `/api/v1/competition/teams/${teamId}/seasons`;
      const response = await $fetch<Season[]>(path);

      if (response) {
        return response;
      } else {
        console.error("Fetched team seasons are null or undefined");
      }
    } catch (e) {
      if (e.statusCode === 404) {
        throw createError({ statusCode: 404, statusMessage: "Team not found" });
      } else {
        console.error("Error fetching team seasons:", e);
      }
    }
  }

  function useFetchParamsTeamSeasons(teamId: number): string {
    return `/api/v1/competition/teams/${teamId}/seasons`;
  }

  function findTeamCurrentOrRecentSeason(teamSeasons: Season[]): Season | undefined {
    // Find the current season
    const currentSeason = teamSeasons ? teamSeasons.find(s => s.isCurrent) : undefined;
    if (currentSeason) {
      return currentSeason;
    }

    //Return the most recent season
    return teamSeasons[teamSeasons.length - 1];
  }

  async function findTeamCompetitionsIds(teamSeasons: Season[]): Promise<RegionOption[]> {
    if (teamSeasons) {
      let competitionIds: number[] = [];
      let competitionSeasons: Season[] = [];

      teamSeasons.forEach(s => {
        const competitionId = s.competitionId;
        competitionIds.push(competitionId);
        competitionSeasons.push(s);
      });

      const { getRegions } = RegionUiService();
      const { webContext } = useWebContext();
      const _regions = await getRegions();
      const competitionsIds = [...new Set(competitionIds)];
      const seasons = [...new Set(competitionSeasons)];

      const competitionsPlayed = _regions.filter(region => {
        return (
          !!region.competitionId[webContext] &&
          region.competitionId[webContext]?.some(id => competitionsIds.includes(id))
        );
      });

      const regions = competitionsPlayed.map(region => {
        const simpleRegion: RegionOption = {
          id: region.id,
          competitionIds: region.competitionId[webContext] || [],
          name: region.name || "Unknown region name",
          seasons: seasons.map(s => s.id),
        };
        return simpleRegion;
      });
      return regions.sort((a, b) => a.id - b.id);
    } else {
      console.warn("Fetched team regions data is empty or missing");
      return [];
    }
  }

  async function getCurrentTeamStaff(teamId: number): Promise<Staff[] | undefined> {
    try {
      const path = `/api/v1/competition/teams/${teamId}/staffs`;
      const team = await $fetch<TeamStaffDTO>(path);

      const response = team?.staffs;
      if (response) {
        return response;
      } else {
        console.error("Fetched current team staff is null");
      }
    } catch (e) {
      console.error("Error fetching current team staff:", e);
    }
  }

  async function getSeasonTeamStaff(seasonId: number, teamId: number): Promise<Staff[] | undefined> {
    try {
      const path = `/api/v1/competition/teams/${teamId}/season-data/${seasonId}/staffs`;
      const team = await $fetch<TeamDTO>(path);

      const response = team?.staffs;
      if (response) {
        return response;
      } else {
        console.error("Fetched season team staff is null");
      }
    } catch (e) {
      console.error("Error fetching season team staff");
    }
  }

  function findTeamManager(team: Team): Staff | undefined {
    const manager = team.staffs?.find(s => s.role === "president");

    if (!manager) {
      console.error("President not found");
      return undefined;
    }

    return manager;
  }

  /*  async function getSeasonTeamStats(seasonId: number, teamId: number): Promise<Team | undefined> {
    try {
      const path = `/api/v1/competition/teams/${teamId}/season-data/${seasonId}/rankings`;
      const team = await $fetch<TeamDTO>(path);

      if (team) {
        return team;
      } else {
        console.error("Fetched season team stats are null");
      }
    } catch (e) {
      console.error("Error fetching seasons team stats");
    }
  }*/

  async function getSeasonTeamMatches(seasonId: number, teamId: number): Promise<Match[] | undefined> {
    try {
      const path = `/api/v1/competition/teams/${teamId}/season-data/${seasonId}/matches`;

      const season = await $fetch<SeasonTeamMatches>(path);
      const matches = season?.matches;

      if (matches) {
        return matches.sort((a, b) => {
          return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;
        });
      } else {
        console.error("Fetched season team matches are null");
      }
    } catch (e) {
      console.error("Error fetching season team matches", e);
    }
  }

  async function getTeamGroupId(seasonId: number, teamId: number): Promise<number | undefined> {
    const teamMatches = await getSeasonTeamMatches(seasonId, teamId);

    if (teamMatches) {
      return teamMatches[0]?.groupId;
    } else {
      console.error("There is no team matches and couldn't get group id");
    }
  }

  async function getTeamStanding(groupId: number, teamId: number): Promise<StandingDTO | undefined> {
    const group = await useGroups().getGroup(groupId);
    let response = undefined;

    if (group?.standings) {
      response = group.standings.find(data => data.team.id === teamId);
    }
    return response;
  }

  async function getSeasonTeamRankings(
    seasonId: number,
    teamId: number
  ): Promise<TeamRankingParameter[] | undefined> {
    try {
      const path = `/api/v1/competition/teams/${teamId}/season-data/${seasonId}/stats`;

      const season = await $fetch<TeamDTO>(path);
      const response = season?.rankings;

      if (response) {
        return response;
      } else {
        console.error("Fetched season team rankings are null");
      }
    } catch (e) {
      console.error("Error fetching season team rankings", e);
    }
  }

  async function getSeasonTeamPlayers(seasonId: number, teamId: number): Promise<Player[] | undefined> {
    try {
      const path = `/api/v1/competition/teams/${teamId}/season-data/${seasonId}/players`;

      const response = await $fetch<Player[]>(path);

      if (response) {
        return response;
      } else {
        console.error("Fetched season team players are null");
      }
    } catch (e) {
      console.error("Error fetching season team players", e);
    }
  }

  async function getLastTurnPlayedByTeamInSeason(
    seasonId: number,
    teamId: number
  ): Promise<string | undefined> {
    const { getSeason } = useSeasons();
    const teamMatches = await getSeasonTeamMatches(seasonId, teamId);
    let response: string | undefined = undefined;

    if (teamMatches) {
      const lastMatchPlayed: Match = teamMatches[teamMatches.length - 1];
      if (lastMatchPlayed) {
        const lastMatchPlayedTurnId: number | null = lastMatchPlayed.turnId;
        const lastMatchPlayedPhaseId: number | null = lastMatchPlayed.phaseId;
        const isHomeTeam: boolean = lastMatchPlayed.participants.homeTeamId === teamId;
        const { value: locale } = useCookie("lang");

        let path = `/api/v1/competition/turns/${lastMatchPlayedTurnId}`;
        const opts = { query: { lang: locale } };
        let turn: TurnDTO | undefined;
        try {
          turn = await $fetch<TurnDTO>(path, opts);
        } catch (e) {
          console.error("Error fetching turn");
        }

        if (turn) {
          const season = await getSeason(seasonId);
          const seasonPhases = season?.phases;
          let phaseIndex;

          const {
            scores: { homeScore, homeScoreP, awayScore, awayScoreP },
          } = lastMatchPlayed;
          const isHomeTeamWinner =
            (typeof homeScore === "number" &&
              typeof awayScore === "number" &&
              isHomeTeam &&
              homeScore > awayScore) ||
            (typeof homeScoreP === "number" &&
              typeof awayScoreP === "number" &&
              isHomeTeam &&
              homeScoreP > awayScoreP);

          const isAwayTeamWinner =
            (typeof homeScore === "number" &&
              typeof awayScore === "number" &&
              !isHomeTeam &&
              homeScore < awayScore) ||
            (typeof homeScoreP === "number" &&
              typeof awayScoreP === "number" &&
              !isHomeTeam &&
              homeScoreP < awayScoreP);

          const isWinner = isHomeTeamWinner || isAwayTeamWinner;

          if (seasonPhases?.length && lastMatchPlayedPhaseId) {
            phaseIndex = seasonPhases.findIndex(phase => phase.id === lastMatchPlayedPhaseId);
            if (seasonId !== 13 && phaseIndex > 0) {
              response = isWinner && turn.name.toLowerCase() === "final" ? turn.name + "W" : turn.name;
            } else if (seasonId === 13 && lastMatchPlayed) {
              response = isWinner && lastMatchPlayed.id === 923 ? turn.name + "W" : turn.name;
            }
          }
        } else {
          console.error("Fetched turn is null");
        }
      }
    }

    return response;
  }
};

// nuxt-ui/plugins/sentry.client.ts

import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin({
  name: "sentry",
  parallel: true,
  async setup(nuxtApp) {
    if (process.env.NODE_ENV === "development") {
      return;
    }

    const vueApp = nuxtApp.vueApp;

    const {
      public: { sentry },
    } = useRuntimeConfig();
    const sentryEnv = sentry.environment;
    const sentryIsDev = sentryEnv === "development";

    if (!sentry.dsn) {
      return;
    }

    Sentry.init({
      app: vueApp,
      dsn: sentry.dsn,
      environment: sentryEnv || "development",
      integrations: getSentryIntegrations(),

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: sentryIsDev ? 0 : 0.05,
      //tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        "0.0.0.0",
        "https://staging.kingsleague.pro",
        "https://kingsleague.pro",
        "https://staging.queensleague.pro",
        "https://queensleague.pro",
      ],

      replaysSessionSampleRate: sentryIsDev ? 0 : 0.05, // Change in prod
      replaysOnErrorSampleRate: sentryIsDev ? 0 : 0.05, // Change in prod if necessary
    });

    // Lazy-load the replay integration to reduce bundle size
    await lazyLoadSentryIntegrations();
  },
});

async function lazyLoadSentryIntegrations() {
  // don't load on server
  //https://nuxt.com/docs/api/advanced/import-meta
  if (!import.meta.client) return;

  const { replayIntegration } = await import("@sentry/vue");
  Sentry.addIntegration(
    replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    })
  );
}

function getSentryIntegrations() {
  // don't load on server
  if (!import.meta.client) return [];
  //https://docs.sentry.io/platforms/javascript/guides/vue/features/vue-router/
  const router = useRouter();
  const browserTracing = Sentry.browserTracingIntegration({
    router,
  });

  return [browserTracing];
}

/* ALTERNATIVE IMPLEMENTATION
export default defineNuxtPlugin(nuxtApp => {
  const router = useRouter();
  const {
    public: { sentry },
  } = useRuntimeConfig();
  const sentryEnv = sentry.environment;
  const sentryIsProd = sentryEnv === "production";

  if (!sentry.dsn) {
    return;
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentryEnv,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Configure this whole part as you need it!

    tracesSampleRate: 0.2, // Change in prod

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      "0.0.0.0",
      "https://staging.kingsleague.pro",
      "https://kingsleague.pro",
      "https://staging.queensleague.pro",
      "https://queensleague.pro",
    ],

    replaysSessionSampleRate: sentryIsProd ? 0.15 : 1.0, // Change in prod
    replaysOnErrorSampleRate: sentryIsProd ? 0.5 : 1.0, // Change in prod if necessary
  });
});*/

import type { SeasonCluster } from "~/src/season-cluster/domain/models/00.SeasonCluster";
import type { Season } from "~/src/season-cluster/domain/models/01.Season";
import type { Gender } from "~/src/Shared/Types";
import { isFutureDate, sortItemsByParam } from "~/src/Shared/utils";

interface CompetitionUiService {
  findCurrentSeasonCluster: (seasonClusters: SeasonCluster[]) => SeasonCluster;
  findCurrentSeasonId: (seasons: Season[]) => number;
  findCurrentSeasonClusterSeasons: (
    genders: Gender[],
    seasonCluster: SeasonCluster,
    competitions: number[]
  ) => Season[];
}

const service = (): CompetitionUiService => ({
  findCurrentSeasonCluster,
  findCurrentSeasonId,
  findCurrentSeasonClusterSeasons,
});

export default service;

function findCurrentSeasonCluster(seasonClusters: SeasonCluster[]): SeasonCluster {
  const currentSeasonCluster = seasonClusters.find(s => s.isCurrent);
  const sortedSeasonClustersByDate = sortItemsByParam(seasonClusters, "finish");

  return currentSeasonCluster || sortedSeasonClustersByDate[sortedSeasonClustersByDate.length - 1];
}

function findCurrentSeasonId(seasons: Season[]): number {
  const currentSeason = seasons.find(s => s.isCurrent);
  const sortedSeasonsByDate = sortItemsByParam(seasons, "finish");
  const { id: lastSeasonId } = sortedSeasonsByDate[sortedSeasonsByDate.length - 1];

  return currentSeason?.id || lastSeasonId;
}

function findCurrentSeasonClusterSeasons(
  genders: Gender[],
  seasonCluster: SeasonCluster,
  competitions: number[]
): Season[] {
  return seasonCluster.seasons.filter(season => {
    const {
      competition: { gender: seasonGender },
    } = season;
    const contextGenderSeason = genders.includes(seasonGender);
    const currentCompetitionSeason = !!competitions && competitions.includes(season.competitionId);
    const activeSeason = season.competition.visible && !isFutureDate(season.start);
    return currentCompetitionSeason && contextGenderSeason && activeSeason;
  });
}

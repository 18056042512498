// src/Shared/utils.ts

/**
 * Checks if a given date is in the past.
 *
 * @param {string} utcDate - The UTC date as a string.
 * @return {boolean | undefined} - Returns true if the date is in the past, false if it's in the future, and undefined if the utcDate is invalid.
 */
export function isPastDate(utcDate: string): boolean | undefined {
  const _date = new Date(utcDate);
  if (_date.toLocaleString() === "Invalid Date") {
    console.error("invalid utcDate", utcDate);
    return undefined;
  }
  const dateTime = _date.getTime();
  const now = new Date().getTime();
  return now > dateTime;
}

/**
 * Checks if the current date is before the given date and time.
 *
 * @param {string} utcDate - The UTC date string to compare.
 * @return {boolean|undefined} Returns true if the current date is before the given date and time, false otherwise.
 * Returns undefined if the utcDate is invalid.
 */
export function isFutureDate(utcDate: string): boolean | undefined {
  const _date = new Date(utcDate);
  if (_date.toLocaleString() === "Invalid Date") {
    console.error("invalid utcDate", utcDate);
    return undefined;
  }
  const dateTime = _date.getTime();
  const now = new Date().getTime();
  return now < dateTime;
}

/**
 * Converts a string to a slug.
 *
 * @param {string|null|undefined} string - The input string to be converted.
 * @return {string} - The slugified string.
 */
export function slugify(string: string | null | undefined): string {
  if (!string) return "";

  const a = "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b = "aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

/**
 * Orders an array of items by a given param (key).
 *
 * @param {array} items - The array of items to be sorted.
 * @param {string} key - The key string used to order the elements.
 * @return {array} - The sorted array of items.
 */
export function sortItemsByParam(items: any[], key: string): any[] {
  return items.sort((a, b) => {
    return a[key] && b[key] && a[key] > b[key] ? 1 : a[key] && b[key] && a[key] < b[key] ? -1 : 0;
  });
}

export function arrowsSliderVisibility(
  direction: string,
  container: HTMLElement | null,
  leftBtn: HTMLElement | null,
  rightBtn: HTMLElement | null
) {
  if (container && leftBtn && rightBtn) {
    if (direction === "left") {
      if (leftBtn && container.scrollLeft === 0) {
        // Is totally left
        leftBtn.classList.add("hidden");
      }
      if (
        rightBtn &&
        Math.round(container.scrollLeft) < Math.round(container.scrollWidth) &&
        rightBtn.classList.contains("hidden")
      ) {
        rightBtn.classList.remove("hidden");
      }
    } else if (direction === "right") {
      if (leftBtn && container.scrollLeft > 0) {
        if (leftBtn.classList.contains("hidden")) leftBtn.classList.remove("hidden");
      }
      if (
        rightBtn &&
        Math.round(container.scrollLeft) ===
          Math.round(container.scrollWidth) - Math.round(container.clientWidth)
      ) {
        // Is totally right
        rightBtn.classList.add("hidden");
      }
    } else if (direction === "general") {
      if (leftBtn && container.scrollLeft > 0) {
        container.addEventListener("scroll", () => {
          if (leftBtn.classList.contains("hidden")) leftBtn.classList.remove("hidden");
          if (leftBtn && container.scrollLeft === 0) leftBtn.classList.add("hidden");
        });
      }

      if (
        rightBtn &&
        Math.round(container.scrollLeft) < Math.round(container.scrollWidth) &&
        rightBtn.classList.contains("hidden")
      ) {
        rightBtn.classList.remove("hidden");
      }

      if (
        rightBtn &&
        Math.round(container.scrollLeft) ===
          Math.round(container.scrollWidth) - Math.round(container.clientWidth)
      ) {
        rightBtn.classList.add("hidden");
      }
    }
  }
}

export function shuffle<T>(array: T[]): T[] {
  let copy = JSON.parse(JSON.stringify(array));

  for (let i = copy.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    // Swap the elements
    let temp = JSON.parse(JSON.stringify(copy[i]));
    copy[i] = JSON.parse(JSON.stringify(copy[j]));
    copy[j] = temp;
  }
  return copy;
}

/**
 * Scrolls the window to the top with a smooth scrolling effect.
 *
 * @function scrollToTop
 * @returns {void}
 */
export const scrollToTop = (offset = 0): void => {
  setTimeout(() => {
    window.scrollTo({ top: offset, behavior: "smooth" });
  }, 0);
};

/**
 * Scrolls the window to the left.
 *
 * @function scrollToLeft
 * @returns {void}
 */
export const scrollToLeft = (offset = 0): void => {
  setTimeout(() => {
    window.scrollTo({ left: offset, behavior: "smooth" });
  }, 0);
};

/**
 * Adds the specified number of hours to the given date.
 *
 * @param {Date} date - The date to add hours to.
 * @param {number} hours - The number of hours to add.
 * @return {Date} - The modified date with the added hours.
 */
export function addHoursToDate(date: string, hours: number): string {
  const _date = new Date(date);
  if (_date.toLocaleString() === "Invalid Date") {
    console.error("addHoursToDate: Invalid Date");
    return date;
  }

  const hoursToAdd = hours * 60 * 60 * 1000;
  _date.setTime(_date.getTime() + hoursToAdd);
  return _date.toISOString();
}

export function breakCacheHash(path: string): string {
  //const d = new Date();
  //let _path = path;
  //let breakCacheString;

  /*
  if (path.includes("/live/")) {
    // 15 sec
    const seconds = Math.floor(d.getSeconds() / 15) * 15;
    breakCacheString = `${d.getHours()}${d.getMinutes()}${seconds < 10 ? "0" : ""}${seconds}`;
    _path += `?${breakCacheString}`;
  } else if (path.includes("/seasons/10") && !path.includes("rankings")) {
    //30 sec
    const seconds = Math.floor(d.getSeconds() / 30) * 30;
    breakCacheString = `${d.getHours()}${d.getMinutes()}${seconds < 10 ? "0" : ""}${seconds}`;
    _path += `?${breakCacheString}`;
  } else if (path.includes("/seasons/10/") && path.includes("rankings")) {
    //30 min
    const minutes = Math.floor(d.getMinutes() / 15) * 15;
    breakCacheString = `${d.getHours()}${minutes < 10 ? "0" : ""}${minutes}`;
    _path += `?${breakCacheString}`;
  }
  */

  return path;
}

/**
 * Replaces the given URL path with its corresponding alias.
 * @param path
 * @returns The URL path with the replaced part or the original if no match is found.
 */
export function setAliasUrl(path: string | undefined): string {
  if (!path) return "";

  const possibleAlias = {
    "https://cms-es.kingsleague.pro/wp-content/uploads": "cms-es",
    "https://cms-es.kingsleague.pro/old_drafts": "drafts-es",
    "https://cms-am.kingsleague.pro/wp-content/uploads": "cms-am",
    "https://s3.eu-central-2.wasabisys.com/kingsleague-cdn.kama.football/account/production": "s3",
    "https://kingsleague-cdn.kama.football/account": "kama",
  };

  for (const [url, alias] of Object.entries(possibleAlias)) {
    if (path.startsWith(url)) {
      return path.replace(url, alias);
    }
  }

  return path;
}
